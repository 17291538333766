.navbar {
  padding-left: 10%;
  padding-right: 10%;
  height: 198px;
}

.navbar .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.is-active {
  color: rebeccapurple;
}

.navbar .top .left ul {
  padding-left: 0px;
  list-style: none;
  display: flex;
}

.navbar .top .right ul {
  list-style: none;
  display: flex;
}

.navbar .top .right img {
  display: none;
}

.navbar .top .left img {
  display: none;
}

.navbar .top .center {
  position: absolute;
  top: 34.93px;
  left: 50%;
  transform: translateX(-50%);
}

.navbar .top .left ul li,
.navbar .top .left ul li a {
  font-family: DescovarAlpha;
  font-weight: normal;
  margin-top: 60px;
  margin-right: 30px;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
}

.navbar .top .right ul li,
.navbar .top .right ul li a {
  font-family: DescovarAlpha;
  font-weight: normal;
  margin-top: 60px;
  margin-left: 30px;
  color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
}

#hamburger-menu {
  background-color: #292929;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;
}

#hamburger-menu img {
  margin: 60px;
  width: 30px;
  height: 30px;
}

#hamburger-menu ul {
  display: block;
  text-align: end;
  margin-right: 20px;
}

#hamburger-menu ul li {
  margin-bottom: 40px;
  list-style: none;
}

#hamburger-menu ul li a {
  font-family: "outfit";
  font-weight: 600;
  font-size: 30px;
  color: #f6c893;
  text-decoration: none;
}

@media only screen and (max-width: 550px) {
  .navbar .top .right ul {
    display: none;
  }

  .navbar .top .right img {
    margin-top: 30px;
    display: block;
  }

  .navbar .top .left ul {
    display: none;
  }

  .navbar .top .left img {
    display: block;
  }
}
