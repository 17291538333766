@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap");

@font-face {
  font-family: 'ProximaNova';
  font-weight: normal;
  src: local('ProximaNova'), url(./fonts/ProximaNova/Proxima-Nova-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'DescovarAlpha';
  font-weight: normal;
  src: local('DescovarAlpha'), url(./fonts/DescovarAlpha/Decovar-Alpha-regular24.ttf) format('truetype');
}

@font-face {
  font-family: 'Marcellus';
  font-weight: normal;
  src: local('Marcellus'), url(./fonts/Marcellus/Marcellus-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: normal;
  src: local('Inter'), url(./fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: local('Inter'), url(./fonts/Inter/Inter-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: local('Inter'), url(./fonts/Inter/Inter-SemiBold.ttf) format('truetype');
}

html {
  background-color: #F8F7F2;
}
