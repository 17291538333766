.home-welcome {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
}

.home-welcome .left {
  width: 55%;
  z-index: 10;
}

.home-welcome .right {
  width: 45%;
  z-index: 10;
  height: 580px;
  text-align: end;
}

.welcome-header-center {
  position: absolute;
  top: 255px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.welcome-header-center h1 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.14em;
  color: #292929;
}

.welcome-header-center p {
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: calc(100% - 120px);
  padding-left: 60px;
  padding-right: 60px;
  margin-top: 6px;
  margin-bottom: 0px;
  text-align: center;
  letter-spacing: 0.14em;
  color: #292929;
}

.welcome-header-center a {
  text-decoration: none;
}

.welcome-header-center button {
  position: absolute;
  margin-top: 22px;
  left: 50%;
  transform: translateX(-50%);
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  background-color: #D7B377;
  font-family: "DescoverAlpha";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  border-radius: 40px;
  border-width: 0px;
  transition: 400ms;
}

.welcome-header-center button:hover {
  scale: 1.1;
}

.welcome-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  height: 100%;
  width: 100%;
}

.home-welcome .right img {
  max-height: 580px;
  max-width: 100%;
}

.home-welcome .left h1 {
  font-family: ProximaNova;
  font-weight: normal;
  font-size: 52px;
  color: #f6c893;
  line-height: 65px;
  margin-bottom: 0px;
  margin-left: 20%;
  margin-top: 0px;
  margin-right: 10px;
  text-transform: capitalize;
}

.home-welcome .left p {
  font-family: ProximaNova;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #faf4f4;
  margin-top: 12px;
  margin-left: 20%;
  margin-right: 10px;
  text-transform: capitalize;
}

.home-welcome .left a {
  text-decoration: none;
}

.home-welcome .left button {
  margin-top: 10%;
  background-color: #b98860;
  border-radius: 32px;
  font-family: "roboto";
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 1.1px;
  border-width: 0px;
  color: white;
  height: 60px;
  padding-left: 60px;
  padding-right: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: 400ms;
}

.home-welcome .left button:hover {
  scale: 1.1;
}

.home-welcome .left ul {
  display: flex;
  list-style: none;
  margin-top: 10%;
  justify-content: center;
  padding-left: 0px;
}

.home-welcome .left ul li {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

.home-welcome .left ul li img {
  width: 40px;
  height: 30px;
}

.home-welcome .left ul li p {
  font-family: "roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.1;
  color: #faf4f4;
  padding: 0;
  margin: 0;
  margin-top: 12px;
  text-transform: capitalize;
}

.navbar {
  position: absolute;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
}

.field {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
}

.mouse {
	width: 27px;
	height: 50px;
	border: 1px solid #ffffff;
	border-radius: 60px;
	position: relative;
}

.mouse::before {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
  -webkit-animation: wheel 2s infinite;
}

.rental-promotion__tag {
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: rgba(255,255,255,0.85);
}

.rental-promotion {
  letter-spacing: 1px;
  font-size: 1rem;
  position: absolute;
  margin: auto;
  z-index: 10;
  pointer-events: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 100px;
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 60px;
	}
}

@media only screen and (max-width: 900px) {
  .home-welcome {
    width: 100%;
  }

  .home-welcome .left {
    width: 100%;
  }

  .home-welcome .left p {
    margin-left: 0;
  }

  .home-welcome .left h1 {
    margin-left: 0;
  }

  .home-welcome .right {
    width: 100%;
    margin-top: 50px;
    text-align: center;
  }

  .home-welcome .right img {
    border-radius: 16px;
    max-width: 100%;
  }

  .welcome-header-center {
    top: 200px;
  }

  .rental-promotion {
    font-size: 11px;
  }
}
