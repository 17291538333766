

.about-component .about-list {
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 50px;
}

.about-component .about-list .column {
    float: left;
    width: 23%;
    padding: 5px;
}

.about-component .about-list::after {
    content: "";
    clear: both;
    display: table;
}

.about-component .about-list .column ul {
    padding-left: 0;
    list-style-type: none;
}

.about-component .about-list .column li {
    padding: 5px;
    margin-bottom: 5px;
}

.about-component .about-list .column li {
    padding: 5px;
    margin-bottom: 5px;
    font-family: 'Inter';
    font-weight: normal;
    font-size: 16px;
    color: #292929;
    max-width: 80%;
}

.about-component .about-list .column .bold {
    font-family: 'DescovarAlpha';
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.2em;
    color: #D7B377;
}

.about-component p {
    padding: 5px;
    margin-bottom: 5px;
    font-family: 'Inter';
    font-weight: normal;
    font-size: 16px;
    line-height: 1.7em;
    color: #292929;
    max-width: 80%;
    padding-left: 10%;
    padding-right: 10%;
}

.about-component h3 {
    font-family: 'DescovarAlpha';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #292929;
    margin-bottom: 20px;
    text-align: start;
    margin-top: 70px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 140.97%;
    letter-spacing: 0.165em;
}

.about-component .image-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 30px;
}

.about-component .image-list .img-item {
    width: 30%;
    margin: 10px;
}

@media screen and (max-width: 768px) {
    /* Switch to 2 columns */
    .about-component .about-list .column {
        width: 46%;
    }

    .about-component .image-list .img-item {
        width: 80%;
    }
}

@media screen and (max-width: 480px) {
    /* Switch to 1 column */
    .about-component .about-list .column {
        width: 100%;
    }
}