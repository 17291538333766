.ameneties-component {

}

.ameneties-component .ameneties-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 100px;
}

.ameneties-component .ameneties-list .column {
    flex-basis: 30%;
}

.ameneties-component .ameneties-list .column ul {
    padding-left: 0;
    list-style-type: none;
}

.ameneties-component .ameneties-list .column ul li::before {
    content: "";
    display: inline-block;
    width: 0.3em;
    height: 0.3em;
    margin-right: 0.5em;
    margin-bottom: 0.2em;
    background-color: #D7B377;
    border-radius: 50%;
}

.ameneties-component .ameneties-list .column li {
    padding: 5px;
    margin-bottom: 5px;
}

.location-list .column li {
    padding: 5px;
    margin-bottom: 5px;
    font-family: 'Inter';
    font-weight: normal;
    font-size: 16px;
}

.ameneties-component h3 {
    font-family: 'DescovarAlpha';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #292929;
    margin-bottom: 20px;
    text-align: start;
    margin-top: 70px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 140.97%;
    letter-spacing: 0.165em;
  }


/* Media queries to adjust the layout for smaller screens */
@media screen and (max-width: 768px) {
    .ameneties-component .ameneties-list {
        flex-direction: column;
    }

    .ameneties-component .ameneties-list .column {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 480px) {
    .ameneties-component .ameneties-list .column {
        flex-basis: 100%;
    }
}