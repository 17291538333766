.home-features {
  padding-top: 60px;
}

.home-features .image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-features .image img {
  max-width: 75%;
  height: auto;
  margin-bottom: 80px;
}

.home-features h1 {
  font-family: "outfit";
  font-weight: 700;
  font-size: 52px;
  line-height: 65px;
  color: #F8F7F2;
  margin-left: 10%;
}

.home-features .image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 30px;
}

.home-features .image-list .img-item {
  width: 30%;
  margin: 10px;
}

@media only screen and (max-width: 950px) {
  .home-features h1 {
    margin-left: 5%;
  }

  .home-features .image-list .img-item {
    width: 100%;
}
}
