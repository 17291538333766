.footer-component {
  padding-left: 5%;
  padding-right: 5%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: start;
  background-color: #292929;
  padding-top: 30px;
}

.footer-item {
  margin-right: 100px;
}

.footer-component .footer-item h5 {
  font-family: "DescovarAlpha";
  font-weight: normal;
  font-size: 20px;
  line-height: 33px;
  color: #D7B377;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.footer-component .travel-items {
  display: flex;
}

.footer-component .travel-items .travel-item {
  text-align: center;
}

.footer-component .travel-items .travel-item:first-child {
  margin-right: 10px;
}

.footer-component .travel-items .travel-item a {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  color: #faf4f4;
  letter-spacing: 0.1em;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  margin-top: 12px;
  text-decoration: none;
}

.footer-component .travel-items .travel-item img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.footer-component .footer-item ul {
  padding-left: 0px;
  margin-top: 0px;
}

.footer-component .footer-item ul li {
  list-style: none;
  margin-bottom: 10px;
}

.footer-component .footer-item ul li a {
  color: white;
  font-family: "Marcellus";
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-decoration: none;
}

.footer-component .footer-item .house-item,
.footer-component .footer-item .contact-item {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.footer-component .footer-item .house-item img,
.footer-component .footer-item .contact-item img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.footer-component .footer-item .house-item p {
  font-family: "Inter";
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: white;
  text-transform: uppercase;
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
}

.footer-component .footer-item .contact-item a {
  font-family: "Inter";
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: white;
  margin: 0;
  margin-left: 12px;
  cursor: pointer;
}

.signature-container {
  background-color: #292929;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  color: #faf4f4;
  padding-bottom: 40px;
}

.signature-container p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.signature-container a {
  color: #faf4f4;
  text-decoration: none;
}

.footer-logo-container {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.footer-logo-container img {
  display: inline-block;
}

.social-logo-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.social-logo-container .first-social {
  background: center no-repeat;
  background-size: contain;
  width: 39px;
  height: 40px;
  float: left;
  cursor: pointer;
}

.social-logo-container .social {
  background: center no-repeat;
  background-size: contain;
  width: 39px;
  height: 40px;
  float: left;
  margin-left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .footer-component {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 600px) {
  .footer-component .footer-item .contact-item a,
  .footer-component .footer-item .house-item p,
  .footer-component .footer-item ul li a,
  .footer-component .footer-item .house-item p {
    font-size: 14px;
  }

  .footer-component .travel-items .travel-item img {
    width: 24px;
    height: 24px;
  }

  .footer-component .footer-item h5 {
    font-size: 18px;
  }

  .footer-component .travel-items .travel-item a,
  .footer-component .footer-item .house-item p {
    font-size: 12px;
  }
}
