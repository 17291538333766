.map-container {
  padding-left: 15%;
  padding-right: 15%;
  margin-top: 100px;
  margin-bottom: 200px;
}

.map-container h2 {
  font-family: "DescovarAlpha";
  font-weight: normal;
  font-size: 40px;
  color: #292929;
  margin-top: 0px;
  margin-bottom: 0px;
}

.google-map {
  height: 500px;
  padding: 0px;
  margin: 0;
  margin-top: 60px;
}

.location-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.location-list .column {
  flex-basis: 30%;
}

.location-list .column ul {
  padding-left: 0;
  list-style-type: none;
  
}

.location-list .column ul li::before {
  content: "";
  display: inline-block;
  width: 0.3em;
  height: 0.3em;
  margin-right: 0.5em;
  margin-bottom: 0.2em;
  background-color: #D7B377;
  border-radius: 50%;
}

.location-list .column li {
  padding: 5px;
  margin-bottom: 5px;
  font-family: 'Inter';
  font-weight: normal;
  font-size: 16px;
}

.map-container h3 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #292929;
  margin-bottom: 20px;
  text-align: start;
  line-height: 140.97%;
  letter-spacing: 0.165em;
}

.map-container a {
  text-decoration: none;
}

.map-container .image-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
}

.map-container .image-list .img-item {
  width: 30%;
  margin: 10px;
}


/* Media queries to adjust the layout for smaller screens */
@media screen and (max-width: 768px) {
  .location-list {
      flex-direction: column;
  }

  .location-list .column {
      flex-basis: 100%;
  }

  .map-container .image-list .img-item {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .location-list .column {
      flex-basis: 100%;
  }
}