.images-slider-component {
  margin-top: 60px;
  margin-bottom: 100px;
}

.images-slider-component h5 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #292929;
  margin-bottom: 40px;
  text-align: start;
  margin-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 140.97%;
  letter-spacing: 0.165em;
}

.images-slider-component .slide-container {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  max-width: 1100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.images-slider-component .slide-container .each-slide {
  position: relative;
}

.images-slider-component .slide-container .lazy {
  width: 100%;
  border-radius: 24px;
}

.images-slider-component .slide-container .indicator {
  display: none;
}

.images-slider-component .slide-container button {
  margin: 20px;
}

@media only screen and (max-width: 1000px) {
  .images-slider-component .slide-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .images-slider-component h5 {
    margin-left: 5%;
  }
}
