.simple-feature-component {
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: column;
    background-color: #F8F7F2;
    padding-top: 30px;
    padding-bottom: 100px;
    margin-bottom: 100px;
}

.simple-feature-component h3 {
    font-family: 'DescovarAlpha';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #292929;
    margin-bottom: 0px;
    text-align: start;
    margin-top: 0px;
    line-height: 140.97%;
    letter-spacing: 0.165em;
}

.simple-feature-component p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 196.47%;
    color: #062061;
    margin-top: 30px;
    margin-right: 58px;
    text-align: start;
    letter-spacing: 0.01em;
}

.simple-feature-component button {
    position: absolute;
    margin-top: 22px;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    background-color: #D7B377;
    font-family: "DescoverAlpha";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    border-radius: 40px;
    border-width: 0px;
    transition: 400ms;
  }
  
  .simple-feature-component button:hover {
    scale: 1.1;
  }
  
@media only screen and (max-width: 950px) {
.simple-feature-component {
    padding-left: 5%;
    padding-right: 5%;
}
}
  
@media only screen and (max-width: 750px) {
.simple-feature-component {
    display: block;
}

.simple-feature-component .left,
.simple-feature-component .right {
    width: 100%;
}

.simple-feature-component .right img {
    margin-top: 90px;
}
}