.image-container {
    position: relative;
}
  
.image-container img {
  width: 100%;
}

.image-container:hover img {
  cursor: pointer;
}

.enlarged-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.enlarged-image img {
  width: 80% !important;
}

.enlarged-image img {
  max-width: 100%;
  max-height: 100%;
}