.book-details {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 200px;
  background-color: #F8F7F2;
}

.book-details h5 {
  font-family: "outfit";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #D7B377;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.book-details .contact-body {
  display: flex;
  justify-content: space-between;
}

.book-details .contact-body .left {
  margin-top: 60px;
}

.book-details .contact-body .right .summary-card {
  width: 400px;
  border: 1px solid #292929;
  filter: drop-shadow(0px 4px 10px rgba(250, 244, 244, 0.75));
  border-radius: 30px;
  box-sizing: border-box;
  background-color: #292929;
  padding: 40px;
}

.book-details .contact-body .right .summary-card h5 {
  font-family: "Inter";
  font-weight: normal;
  font-size: 20px;
  line-height: 27.5px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0px;
  letter-spacing: 0.1em;
  margin-bottom: 50px;
}

.book-details .contact-body .right .summary-card button {
  background-color: #b98860;
  height: 60px;
  border-radius: 32px;
  font-family: "roboto";
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  color: white;
  text-transform: uppercase;
  padding-left: 58px;
  padding-right: 58px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-width: 0;
  margin-top: 30px;
  cursor: pointer;
}

.book-details .contact-body .right .summary-card .request-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.book-details .contact-body .right .summary-card .request-row .label {
  color: #b98860;
  font-family: "roboto";
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 0px;
}

.book-details .contact-body .right .summary-card .request-row .value {
  color: #ffffff;
  font-family: "Inter";
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.book-details input {
  display: inline;
  border: none;
  outline: none;
  background-color: #F8F7F2;
  color: #292929;
  font-family: "Inter";
  font-size: 18px;
  font-weight: normal;
  line-height: 21px;
  margin-bottom: 0px;
  margin-top: 40px;
  width: 100%;
  min-width: 500px;
}

.book-details .line {
  background-color: #b98860;
  height: 2px;
  width: 80%;
  margin-top: 12px;
}

@media only screen and (max-width: 1000px) {
  .book-details .contact-body .left {
    margin-top: 40px;
  }
  .book-details .contact-body {
    flex-direction: column;
  }

  .book-details .contact-body .left {
    width: 100%;
  }

  .book-details .contact-body .right {
    margin-top: 100px;
    display: flex;
    justify-content: space-around;
  }

  .book-details .contact-body .right .contact-info {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .book-details input {
    min-width: 200px;
  }
  .book-details .contact-body .right .summary-card {
    width: 80%;
    padding: 20px;
  }

  .book-details .contact-body .right .summary-card button {
    height: 50px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
  }
}
