.testimonials-layout {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 200px;
}

.testimonials-layout #type {
  color: #D7B377;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  font-family: "outfit";
}

.testimonials-layout h2 {
  color: #292929;
  font-family: "DescovarAlpha";
  font-weight: 400;
  font-size: 40px;
  line-height: 140.97%;
  letter-spacing: 0.165em;
}

.testimonials-layout .testimonials-container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  flex-wrap: nowrap;
  width: 100%;
}

.testimonials-layout .testimonials-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.testimonials-layout .testimonials-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.testimonials-layout .testimonial-container:first-child {
  margin-left: 30px;
}
