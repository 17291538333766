.testimonials-component {
  text-align: center;
  margin-right: 40px;
  background-color: #ffffff;
  width: 200px;
  min-width: 200px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
  box-shadow: 0px 4px 20px rgba(255, 255, 255, 0.08);
}

.testimonials-component #review {
  color: #292929;
  font-size: 13px;
  font-family: "Marcellus";
  font-weight: 400;
  line-height: 27.5px;
  text-align: start;
}

.testimonials-component #date {
  color: #575757eb;
  font-size: 15px;
  font-family: 'Marcellus';
  font-style: normal;
  font-weight: 400;
  line-height: 27.5px;
  text-align: start;
}

.testimonials-component #user {
  color: #292929;
  font-size: 16px;
  font-family: "Marcellus";
  font-weight: 600;
  line-height: 27.5px;
  text-align: start;
}

.testimonials-component .reviewer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 46px;
}

.testimonials-component .reviewer p {
  text-align: start;
  margin-left: 20px;
  margin-bottom: 0px;
}

.testimonials-component #name {
  text-transform: uppercase;
  font-family: "outfit";
  font-weight: 600;
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
}

.testimonials-component #reviewer-title {
  font-family: "roboto";
  font-size: 18px;
  font-weight: 400;
  color: #f3efe0;
  line-height: 27.5px;
  margin-top: 0px;
}

.testimonials-component:first-child {
  margin-left: 110px;
}
