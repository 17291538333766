.feature-component {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 100px;
  align-items: center;
  background-color: #F8F7F2;
}

.feature-component .left {
  width: 75%;
  align-items: center;
}

.feature-component .left h3 {
  font-family: 'DescovarAlpha';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #292929;
  margin-bottom: 0px;
  text-align: start;
  margin-top: 0px;
  line-height: 140.97%;
  letter-spacing: 0.165em;
}

.feature-component .left p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 196.47%;
  color: #062061;
  margin-top: 30px;
  margin-right: 58px;
  text-align: start;
  letter-spacing: 0.01em;
}

.feature-component .right {
  align-items: center;
  margin-top: 40px;
}

.feature-component .right img {
  width: 100%;
  max-height: 700px;
}

.feature-component .left a {
  text-decoration: none;
}

.feature-component .left button {
  position: absolute;
  margin-top: 22px;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  background-color: #D7B377;
  font-family: "DescoverAlpha";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  border-radius: 40px;
  border-width: 0px;
  transition: 400ms;
}

.feature-component .left button:hover {
  scale: 1.1;
}

@media only screen and (max-width: 950px) {
  .feature-component {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 750px) {
  .feature-component {
    display: block;
  }

  .feature-component .left,
  .feature-component .right {
    width: 100%;
  }

  .feature-component .right img {
    margin-top: 90px;
  }

  .feature-component .left p {
    margin-right: 0px; 
  }
}
